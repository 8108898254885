import React, { FC, Dispatch, useEffect, useState, useRef, useCallback } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as Svg1C } from "@assets/1C.svg";
import { ReactComponent as SvgOutlook } from "@assets/outlook.svg";
import { ReactComponent as SvgQuestion } from "@assets/question.svg";
import { ReactComponent as SvgZoom } from "@assets/zoom.svg";
import { RemindMeetings } from "@components/screens/profile/components/Settings/components/RemindMeetings";
import { LoaderRenderWithCondition } from "@hoc";
import { IStateUser } from "@interfaces/user.interface";
import { api, StoreTagTypes } from "@services/api";
import { useGetOutlookAuthStatusQuery, useRevokeAuthOutlookMutation } from "@services/userApi";
import { useGetZoomAuthStatusQuery, useGetZoomInfoQuery, useRevokeAuthZoomMutation } from "@services/userApi";
import { userSettings } from "@services/userSettings.service";
import { setIsHidePersonalTasks } from "@store/calendar/slice";
import { selectNotifications } from "@store/notification";
import { setActiveTabProfile } from "@store/notification/slice";
import { selectIsDogmaUser, userSelector } from "@store/user";
import { selectIsLoadingUserSettings, selectShowBusiness, selectShowPersonal, selectUserPersonalSettings } from "@store/userSettings";
import { getPersonalSettings, updatePersonalSettings } from "@store/userSettings/api";
import { boxCenterCss, Spacer } from "@styles/styles";
import { Colors } from "@theme/colors";
import { HeaderModal, TextFont } from "@ui";
import { isTablet } from "@utils/isTablet";

import { TypeTab } from "../../types";
import { ZoomSuccess } from "../ZoomSuccess";

import { AuthOutlook } from "./components/AuthOutlook";
import { SettingsOptionItem } from "./components/SettingsOptionItem";
import { LinkToZoom } from "./linkToZoom/LinkToZoom";

interface ISettings {
  onClose: () => void;
  setTab?: Dispatch<TypeTab>;
  user: IStateUser;
}

export const Settings: FC<ISettings> = ({ onClose, user }) => {
  const [params] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const storedShowBusiness = useRef<string | null>(null);
  const storedShowPersonal = useRef<string | null>(null);

  const settings = useSelector(selectUserPersonalSettings);

  const [isVisibleOutlock, setIsVisibleOutlock] = React.useState(false);
  const [activeTab, setActiveTab] = useState<"meets" | "integrations">("meets");
  const [isToggle1Enabled, setIsToggle1Enabled] = useState(true);
  const [isToggle2Enabled, setIsToggle2Enabled] = useState(true);
  const [isCancelNotificationsPastMeetings, setCancelNotificationsPastMeetings] = useState<boolean>(
    settings?.deleteNotificationsReminderMeetingAfterCompleted,
  );
  const [isSyncOneSToggleEnabled, setIsSyncOneSToggleEnabled] = useState(false);
  const [shouldUpdatePreferences, setShouldUpdatePreferences] = useState(false);
  const [link, setLink] = useState("");
  const [isVisibleZoomSuccess, setIsVisibleZoomSuccess] = useState(false);
  const fetchedShowOneS = settings?.oneSTasksSync ?? false;

  const fetchedShowBusiness = useSelector(selectShowBusiness);
  const fetchedShowPersonal = useSelector(selectShowPersonal);
  const isLoadingUserSettings = useSelector(selectIsLoadingUserSettings);
  const { directorId } = userSelector();
  const isDogmaUser = useSelector(selectIsDogmaUser);
  const { activeTabProfile } = useSelector(selectNotifications);

  const { data: outlookAuthData } = useGetOutlookAuthStatusQuery(undefined, { skip: !isDogmaUser });
  const [revokeAuthOutlook] = useRevokeAuthOutlookMutation();

  //hidden for vs 1.23
  // const { data: zoomStatus, refetch } = useGetZoomAuthStatusQuery(undefined);
  // const { data: zoomInfo, isFetching } = useGetZoomInfoQuery(undefined, { skip: zoomStatus !== "AUTHORIZED" });
  // const [revokeAuthZoom] = useRevokeAuthZoomMutation();

  useEffect(() => {
    if (location.pathname === "/profile/zoom-success" && params.get("code")) {
      setIsVisibleZoomSuccess(true);
      userSettings
        .authZoom(params.get("code"))
        // .then(refetch)
        .then(() => dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Users, id: "ZOOM-INFO" }])));
    }
  }, [location.pathname, params]);

  useEffect(() => {
    if (activeTabProfile) {
      setActiveTab(activeTabProfile === "integrations" ? "integrations" : undefined);
      dispatch(setActiveTabProfile(undefined));
    } else if (params.get("tab") === "integrations") setActiveTab("integrations");
  }, [activeTabProfile, params]);

  const handleToggle1Change = useCallback(() => {
    if (isToggle2Enabled) {
      setIsToggle1Enabled(!isToggle1Enabled);
      setShouldUpdatePreferences(true);
    }
    dispatch(api.util.invalidateTags([StoreTagTypes.Meet, StoreTagTypes.Calendar]));
  }, [isToggle1Enabled, isToggle2Enabled]);

  const handleToggle2Change = useCallback(() => {
    if (isToggle1Enabled) {
      dispatch(setIsHidePersonalTasks(isToggle2Enabled));
      setIsToggle2Enabled(!isToggle2Enabled);
      setShouldUpdatePreferences(true);
    }
    dispatch(api.util.invalidateTags([StoreTagTypes.Meet, StoreTagTypes.Calendar]));
  }, [isToggle1Enabled, isToggle2Enabled]);

  const handeleOneSToggle = () => {
    setIsSyncOneSToggleEnabled(!isSyncOneSToggleEnabled);
    setShouldUpdatePreferences(true);
  };

  const handleCancelNotifications = () => {
    setCancelNotificationsPastMeetings(!isCancelNotificationsPastMeetings);
    setShouldUpdatePreferences(true);
  };

  const getStoredUserPreferences = () => {
    storedShowBusiness.current = localStorage.getItem("showBusiness");
    storedShowPersonal.current = localStorage.getItem("showPersonal");

    if (storedShowBusiness.current !== null && storedShowPersonal.current !== null) {
      setIsToggle1Enabled(!storedShowBusiness.current);
      setIsToggle2Enabled(!storedShowPersonal.current);
    }
  };

  const updateDisplayPreferences = async () => {
    localStorage.setItem("showBusiness", JSON.stringify(isToggle1Enabled));
    localStorage.setItem("showPersonal", JSON.stringify(isToggle2Enabled));

    dispatch(
      updatePersonalSettings({
        id: directorId,
        body: {
          showBusinessTasks: isToggle1Enabled,
          showBusinessEvents: isToggle1Enabled,
          showPersonalTasks: isToggle2Enabled,
          showPersonalEvents: isToggle2Enabled,
          oneSTasksSync: isSyncOneSToggleEnabled,
          deleteNotificationsReminderMeetingAfterCompleted: isCancelNotificationsPastMeetings,
        },
      }),
    );

    setShouldUpdatePreferences(false);
  };

  useEffect(() => {
    getStoredUserPreferences();
    dispatch(getPersonalSettings());
  }, []);

  useEffect(() => {
    shouldUpdatePreferences && updateDisplayPreferences();
  }, [shouldUpdatePreferences]);

  useEffect(() => {
    setIsToggle1Enabled(Boolean(fetchedShowBusiness));
    localStorage.setItem("showBusiness", JSON.stringify(fetchedShowBusiness));
  }, [fetchedShowBusiness, storedShowBusiness.current]);

  useEffect(() => {
    setIsToggle2Enabled(Boolean(fetchedShowPersonal));
    localStorage.setItem("showPersonal", JSON.stringify(fetchedShowPersonal));
  }, [fetchedShowPersonal, storedShowPersonal.current]);

  useEffect(() => {
    setIsSyncOneSToggleEnabled(fetchedShowOneS);
  }, [fetchedShowOneS]);

  return (
    <ContentWrap>
      <HeaderModal
        title="settings"
        leftSide={{ onPressClose: onClose }}
        styleContainer={isTablet && { backgroundColor: Colors.LIGHT.disabled, paddingTop: 40 }}
      />
      <Content>
        <LineTabs>
          <ButtonTab position="left" isActive={activeTab === "meets"} onClick={() => setActiveTab("meets")}>
            <TextFont size={14} color={activeTab === "meets" ? Colors.LIGHT.white : Colors.LIGHT.text.accent} align="center">
              <FormattedMessage id="Встречи" />
            </TextFont>
          </ButtonTab>
          <ButtonTab position="right" isActive={activeTab === "integrations"} onClick={() => setActiveTab("integrations")}>
            <TextFont size={14} color={activeTab === "integrations" ? Colors.LIGHT.white : Colors.LIGHT.text.accent} align="center">
              <FormattedMessage id="integrations" />
            </TextFont>
          </ButtonTab>
        </LineTabs>

        <div>
          {activeTab === "meets" && (
            <LoaderRenderWithCondition condition={isLoadingUserSettings}>
              <div>
                <TextFont size={18} weight="700" color={Colors.LIGHT.text.darkGreen}>
                  <FormattedMessage id="settingsTaskMeetTitle" defaultMessage="Отображение встреч и задач" />
                </TextFont>
                <SettingsOptionItem
                  textId={"settingsBusinessMeetingsOption"}
                  defaultText="Отображать бизнес встречи и задачи"
                  isToggleEnabled={isToggle1Enabled}
                  onToggleChange={handleToggle1Change}
                  disableToggle={!isToggle2Enabled}
                />
                <SettingsOptionItem
                  textId={"settingsPersonalMeetingsOption"}
                  defaultText="Отображать личные встречи и задачи"
                  isToggleEnabled={isToggle2Enabled}
                  onToggleChange={handleToggle2Change}
                  disableToggle={!isToggle1Enabled}
                />
                <Spacer px={10} />
                <TextFont size={12} color={Colors.LIGHT.text.darkGreen}>
                  <FormattedMessage
                    id="settingsTaskMeetDescription"
                    defaultMessage="При выключенном параметре соответствующие встречи и задачи не отображаются в списке задач и календарях"
                  />
                </TextFont>

                <SettingsOptionItem
                  textId={"deleteNotificationsPastMeetings"}
                  isToggleEnabled={settings?.deleteNotificationsReminderMeetingAfterCompleted}
                  onToggleChange={handleCancelNotifications}
                  style={{ height: 56 }}
                  disableToggle={false}
                />
                <RemindMeetings durations={settings?.notificationDurations} types={settings?.notificationTypes} currentId={user?.id} />
              </div>
            </LoaderRenderWithCondition>
          )}

          {activeTab === "integrations" && (
            <>
              <LinkToZoom setLink={setLink} link={link} />
              <ListActions>
                {isDogmaUser && (
                  <ActionWrap>
                    <SvgOutlook />
                    <BlockAction>
                      <TextWrap>
                        <TextFont size={18}>Outlook</TextFont>
                        {/* TempHide for v1.22.2 */}
                        {/* <SvgQuestion fill={Colors.LIGHT.background.grey} /> */}
                      </TextWrap>
                      {outlookAuthData?.status === "AUTHORIZED" ? (
                        <BlockAction>
                          <TextFont size={14} weight="700">
                            <FormattedMessage id="synchronized" />
                          </TextFont>
                          <button onClick={revokeAuthOutlook}>
                            <TextFont size={16} color={Colors.LIGHT.red}>
                              <FormattedMessage id="unlink" />
                            </TextFont>
                          </button>
                        </BlockAction>
                      ) : (
                        <ButtonItem onClick={() => setIsVisibleOutlock(true)}>
                          <TextFont size={16} align="center">
                            <FormattedMessage id="createLinkOutlook" />
                          </TextFont>
                        </ButtonItem>
                      )}
                    </BlockAction>
                  </ActionWrap>
                )}
                {isDogmaUser && (
                  <ActionWrap>
                    <Svg1C />
                    <BlockAction>
                      <TextWrap>
                        <TextFont size={18}>1С:Документооборот</TextFont>
                        {/* TempHide for v1.22.2 */}
                        {/* <SvgQuestion fill={Colors.LIGHT.background.grey} /> */}
                      </TextWrap>
                      {isSyncOneSToggleEnabled ? (
                        <BlockAction>
                          <TextFont size={14} weight="700">
                            <FormattedMessage id="synchronized" />
                          </TextFont>
                          <button onClick={handeleOneSToggle}>
                            <TextFont size={16} color={Colors.LIGHT.red}>
                              <FormattedMessage id="unlink" />
                            </TextFont>
                          </button>
                        </BlockAction>
                      ) : (
                        <ButtonItem onClick={handeleOneSToggle}>
                          <TextFont size={16} align="center">
                            <FormattedMessage id="createLink" />
                          </TextFont>
                        </ButtonItem>
                      )}
                    </BlockAction>
                  </ActionWrap>
                )}
                {/* hidden for vs 1.23 */}
                {/* <LoaderRenderWithCondition condition={isFetching}>
                  <ZoomWrapper>
                    <SvgZoom />
                    <BlockAction>
                      <LinkWrap>
                        <TextFont size={18}>Zoom Meetings</TextFont>
                        <a href="/instruction_zoom" target="_blank">
                          <SvgQuestion fill={Colors.LIGHT.grey} />
                        </a>
                      </LinkWrap>
                      {zoomInfo ? (
                        <BlockAction>
                          <TextFont weight="700" size={14}>
                            {zoomInfo?.firstName} {zoomInfo?.lastName}
                          </TextFont>
                          <button onClick={revokeAuthZoom}>
                            <TextFont size={16} color={Colors.LIGHT.red}>
                              Отвязать
                            </TextFont>
                          </button>
                        </BlockAction>
                      ) : (
                        <LinkZoom type="button" target="_blank" href={process.env.REACT_APP_BASE_URL_INTAGRATE_ZOOM}>
                          <TextFont size={16}>
                            <FormattedMessage id="createLink" />
                          </TextFont>
                        </LinkZoom>
                      )}
                    </BlockAction>
                  </ZoomWrapper>
                </LoaderRenderWithCondition> */}
              </ListActions>
            </>
          )}
        </div>
      </Content>
      {isVisibleOutlock && <AuthOutlook onClose={() => setIsVisibleOutlock(false)} />}

      <ZoomSuccess isVisible={isVisibleZoomSuccess} onClose={() => setIsVisibleZoomSuccess(false)} />
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  height: 100%;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 85%;
  overflow-y: auto;
  margin-top: 20px;
  padding: 0 12px;
  gap: 30px;
`;
const LineTabs = styled.div`
  ${boxCenterCss};
  margin-bottom: 10px;
`;
const ButtonTab = styled.button<{ position: string; isActive: boolean }>`
  max-width: 350px;
  width: 50%;
  padding: 8px 0;
  border: 1px solid ${Colors.LIGHT.text.accent};
  background-color: ${Colors.LIGHT.white};
  ${({ position }) =>
    position === "left"
      ? `
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
   `
      : `
    border-top-right-radius: 4px;
    border-bottom-  -radius: 4px;
   `}
  ${({ isActive }) =>
    isActive &&
    `
    background-color: ${Colors.LIGHT.text.accent};
   `}
`;

const ZoomWrapper = styled.section`
  display: flex;
  gap: 16px;
  align-items: center;
`;
const ListActions = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 24px;
`;
const ActionWrap = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
`;
const BlockAction = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;
const TextWrap = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;
const LinkWrap = styled.div`
  ${boxCenterCss};
  justify-content: space-between;
  gap: 10px;
`;
const ButtonItem = styled.button`
  align-self: flex-start;
  padding: 4px 8px;
  border: 1px solid ${Colors.LIGHT.background.grey};
  border-radius: 8px;
`;
const LinkZoom = styled.a`
  align-self: flex-start;
  padding: 4px 8px;
  border: 1px solid ${Colors.LIGHT.background.grey};
  border-radius: 8px;
`;
