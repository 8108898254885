import React, { useMemo, useState } from "react";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { ITask, TOneSTaskResolutions, WorkDay } from "@interfaces/businessGoals.interface";
import { ModalClose } from "@screens/day/components/ListTasks/components/modalClose";
import { useWorkDaysController } from "@screens/day/components/ListTasks/Modal/components/addWorkDaysReminder";
import emitter from "@services/emitter";
import { PlanningWork } from "@shared/planningWork";
import { selectAttachedEvents } from "@store/attachedMeetingTasks/selectors";
import { TaskSubtypes, complete } from "@store/businessTask";
import { reopen, takeMe, close, takeToWork, setOnesTaskResolution } from "@store/businessTask/api";
import { selectCurrentDate } from "@store/screenDay";
import { selectUserPermissoins } from "@store/user";
import { IButton, KeysButtons, TaskActions } from "@ui/taskActions";
import { toFormatDate, statusOfDispatchEvent } from "@utils";
import { shouldShowWorkdayReminder } from "@utils/shouldShowWorkdayReminder";

import { ModalDelegate } from "../../components/modalDelegate/ModalDelegate";
import { tempWorkDay } from "../../Modal";
import { AddResolutionCommentModal } from "../../Modal/components/viewSave/components/resolutionOneSActionButtons/AddResolutionCommentModal";
import { OneSResolutions, resolutionActions } from "../../Modal/components/viewSave/components/resolutionOneSActionButtons/constants";

interface IProps {
  item: ITask;
  isVisible: boolean;
  pos: { left: number; top: number };
}

export const ButtonsAllTasks = ({ item, pos, isVisible }: IProps) => {
  const dispatch = useDispatch<any>();

  const [isDelegate, setIsDelegate] = useState(false);
  const [isOpenCommentsModal, setOpenCommentsModal] = useState(false);
  const [buttonType, setButtonType] = useState("");
  const [isClose, setIsClose] = useState(false);
  const [isAddDay, setIsAddDay] = useState(false);
  const isPersonalTask = item?.subType === TaskSubtypes.Personal;
  const [isAddWorkDayReminderVisible, setIsWorkDayReminderVisible] = useState(false);

  const permissions = useSelector(selectUserPermissoins);
  const dateNow = useSelector(selectCurrentDate);

  const [WorkDaysModals] = useWorkDaysController({
    task: item,
    isPersonalTask,
    isAddWorkDayReminderVisible,
    setIsWorkDayReminderVisible,
  });

  const allResolutions = {
    AGREED: {
      onClick: () => {
        dispatch(setOnesTaskResolution({ taskId: item.id, resolution: OneSResolutions.AGREED }));
      },
    },
    AGREED_WITH_REMARKS: {
      onClick: () => {
        setOpenCommentsModal(true);
        setButtonType(OneSResolutions.AGREED_WITH_REMARKS);
      },
    },
    EXECUTED: {
      onClick: () => {
        setOpenCommentsModal(true);
        setButtonType(OneSResolutions.EXECUTED);
      },
    },
    RETURN: {
      onClick: () => {
        setOpenCommentsModal(true);
        setButtonType(OneSResolutions.RETURN);
      },
    },
    NOT_AGREED: {
      onClick: () => {
        setOpenCommentsModal(true);
        setButtonType(OneSResolutions.NOT_AGREED);
      },
    },
    FAMILIARIZED: {
      onClick: () => {
        setOpenCommentsModal(true);
        setButtonType(OneSResolutions.FAMILIARIZED);
      },
    },
    REPEAT_AGREEMENT: {
      onClick: () => {
        setOpenCommentsModal(true);
        setButtonType(OneSResolutions.REPEAT_AGREEMENT);
      },
    },
    COMPLETE_AGREEMENT: {
      onClick: () => {
        setOpenCommentsModal(true);
        setButtonType(OneSResolutions.COMPLETE_AGREEMENT);
      },
    },
    COMPLETE: {
      onClick: () => {
        dispatch(setOnesTaskResolution({ taskId: item.id, resolution: OneSResolutions.COMPLETE }));
      },
    },
  };

  const handleReady = () => {
    dispatch(complete({ id: item.id, isPersonalTask }));
  };

  const handleTakeInWork = () => {
    setIsAddDay(true);
  };

  const handleSaveTakeInWork = async (days: WorkDay[]) => {
    const dateWeek = dayjs(days[0]?.date);
    const response = await dispatch(
      takeToWork({ id: item.id, isPersonalTask, date: toFormatDate(dateWeek), isAdd: dateWeek.isSame(dateNow, "date") }),
    );

    if (statusOfDispatchEvent(response) === "fulfilled") {
      setIsAddDay(false);
    }
  };

  const handleClose = (reason: string, comment: string) => {
    dispatch(close({ amount: { id: item.id, reason, comment }, isPersonalTask }));
  };

  const handleDelegate = () => {
    setIsDelegate(true);
  };

  const handleReturnToWork = () => {
    dispatch(reopen({ id: item.id, isPersonalTask }));
    setIsWorkDayReminderVisible(true);
  };

  const handleTakeBack = () => {
    dispatch(takeMe(item.id));

    if (shouldShowWorkdayReminder(item.workDays)) {
      setIsWorkDayReminderVisible(true);
    }
  };

  const handlersOnPress: Partial<Record<KeysButtons, () => void>> = {
    ready: handleReady,
    takeToWork: handleTakeInWork,
    delegate: handleDelegate,
    close: () => setIsClose(true),
    returnToWork: handleReturnToWork,
    takeBack: handleTakeBack,
  };

  const delegatedButton = useMemo(() => {
    const key = item.delegatedToUser ? "takeBack" : "delegate";

    if (permissions.CAN_DELEGATE && item.type === "TASK" && item.subType !== TaskSubtypes.Personal) {
      return { [key]: { onClick: handlersOnPress[key] } };
    }
    return {};
  }, [item.delegatedToUser, item.subType, item.type, permissions.CAN_DELEGATE]);

  const config = useMemo((): Partial<Record<KeysButtons, IButton>> => {
    if (item.isFromOneS && item.status !== "COMPLETED") {
      const currentResolutions = Object.keys(allResolutions)
        .filter((key) => item?.oneSTaskAvailableResolutions?.includes(key as TOneSTaskResolutions))
        .reduce(
          (obj, key) => ({
            ...obj,
            [key]: allResolutions[key],
          }),
          {},
        );

      return currentResolutions;
    }
    if (!item.isFromOneS) {
      switch (item.status) {
        case "OPEN":
          return {
            ready: { onClick: handlersOnPress.ready },
            takeToWork: { onClick: handlersOnPress.takeToWork },
            ...delegatedButton,
            close: { onClick: handlersOnPress.close },
          };

        case "PAUSED":
          return {
            returnToWork: { onClick: handlersOnPress.returnToWork },
            close: { onClick: handlersOnPress.close },
          };
        case "CLOSED":
        case "COMPLETED":
          return {
            returnToWork: { onClick: handlersOnPress.returnToWork },
          };
      }
    }
  }, [delegatedButton, item.status, item?.oneSTaskAvailableResolutions]);

  return (
    <>
      <RenderWithCondition condition={isVisible && pos.top > 100 && !isEmpty(config)}>
        <TaskActions pos={isEmpty(config) ? { left: 0, top: 0 } : pos} configuration={config} />
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isDelegate}>
        <ModalDelegate close={() => setIsDelegate(false)} delegatedToUser={item.delegatedToUser} task={item} />
      </ModalRenderWithCondition>
      <ModalRenderWithCondition condition={isClose}>
        <ModalClose isVisible={isClose} setIsVisible={setIsClose} onSave={handleClose} />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isOpenCommentsModal}>
        <AddResolutionCommentModal
          isVisible={isOpenCommentsModal}
          setIsVisible={setOpenCommentsModal}
          buttonMeta={resolutionActions[buttonType]}
          taskId={item.id}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isAddDay}>
        <PlanningWork
          isVisible={isAddDay}
          setIsVisible={setIsAddDay}
          isTime={false}
          startDate={toFormatDate(dayjs())}
          startDays={item?.workDays ?? []}
          tempObject={tempWorkDay}
          onSave={handleSaveTakeInWork}
          keyTitle="workDay"
          closeAfterPress={false}
          deadline={item.plannedEndDate}
          isMultiple={false}
        />
      </ModalRenderWithCondition>
      {WorkDaysModals}
    </>
  );
};
