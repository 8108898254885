import React, { useState, memo, useMemo, FC } from "react";

import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as SvgAnalysis } from "@assets/analysis.svg";
import { ReactComponent as SvgCalendar } from "@assets/calendar.svg";
// import { ReactComponent as SvgExpand } from "@assets/expand.svg";
import { ReactComponent as SvgNotAnswerMeets } from "@assets/three_persons.svg";
import { ReactComponent as SvgToday } from "@assets/today.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { useGetUnansweredCountQuery } from "@services/meetApi";
import { SelectRole, UnansweredMeets } from "@shared";
import { setIsVisibleAppCalendar } from "@store/calendar/slice";
import { saveDate, selectCurrentDate } from "@store/screenDay";
import { themeSelector } from "@store/theme";
import { boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { ArrowSvg } from "@ui";
import { TextFont } from "@ui/TextFont";
import { calendar, showToast } from "@utils";
import { myTracker } from "@utils/myTracker";

import { Modal } from "./modal";

export const Header: FC = memo(function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTablet } = useDimension();
  const { extra } = themeSelector();

  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleUnansweredMeets, setIsVisibleUnansweredMeets] = useState(false);

  const date = useSelector(selectCurrentDate);

  const { data: unansweredCount } = useGetUnansweredCountQuery();

  const editDay = (arg: "prev" | "next") => {
    dispatch(saveDate(date.add(arg === "prev" ? -1 : 1, "day")));
  };

  const renderDate = useMemo(() => `${date.locale("ru").format("DD MMMM")}, ${calendar.getWeekDayRu(date.day())}`, [date]);

  const handleClickCalendarIcon = () => {
    setIsVisible(true);
    dispatch(setIsVisibleAppCalendar(true));
  };

  return (
    <ContentWrap data-testid="dayHeader">
      <BlockLeft>
        <RenderWithCondition condition={!date.isAfter(dayjs(), "date")}>
          <button
            disabled={extra === "ASSISTANT"}
            onClick={() => {
              myTracker("AnalysisDayClick");
              navigate("analysis");
            }}
          >
            <SvgAnalysis fill={extra === "ASSISTANT" ? Colors.LIGHT.lightYellow100 : Colors.LIGHT.text.accent} />
          </button>
        </RenderWithCondition>

        <SelectRole />
      </BlockLeft>
      <BlockCenter>
        <ButtonArrow onClick={() => editDay("prev")}>
          <ArrowSvg type="left" fill={Colors.LIGHT.placeholder.main} />
        </ButtonArrow>
        <TextFont type="bold" size={16} weight="700">
          {renderDate}
        </TextFont>
        <ButtonArrow onClick={() => editDay("next")}>
          <ArrowSvg type="right" fill={Colors.LIGHT.placeholder.main} />
        </ButtonArrow>
      </BlockCenter>
      <BlockRight>
        <ButtonAnswerMeets
          onClick={() => setIsVisibleUnansweredMeets(true)}
          disabled={!unansweredCount}
          unansweredCount={!!unansweredCount}
        >
          <SvgNotAnswerMeets fill={unansweredCount ? Colors.LIGHT.red : Colors.LIGHT.grey500} />
          {!!unansweredCount && (
            <TextFont size={14} color={Colors.LIGHT.red}>
              {unansweredCount}
            </TextFont>
          )}
        </ButtonAnswerMeets>
        <>
          <ButtonIcon
            onClick={() => {
              showToast("today");
              dispatch(saveDate(dayjs()));
            }}
          >
            <SvgToday fill={extra === "ASSISTANT" ? Colors.ASSISTANT.background.darkGreen : Colors.LIGHT.text.accent} />
          </ButtonIcon>
          <ButtonIcon onClick={handleClickCalendarIcon}>
            <SvgCalendar fill={extra === "ASSISTANT" ? Colors.ASSISTANT.background.darkGreen : Colors.LIGHT.text.accent} />
          </ButtonIcon>
        </>
      </BlockRight>
      <ModalRenderWithCondition condition={isVisible}>
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} isFullWidth={isTablet} />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleUnansweredMeets}>
        <UnansweredMeets onClose={() => setIsVisibleUnansweredMeets(false)} />
      </ModalRenderWithCondition>
    </ContentWrap>
  );
});

const ContentWrap = styled.section`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 0.2fr auto 0.2fr;
  align-items: center;
  padding-bottom: 8.5px;
  border-bottom: 1px solid ${Colors.LIGHT.disabled};
`;
const BlockLeft = styled.div`
  display: flex;
  flex: 1;
  padding-left: 5px;
  gap: 16px;
`;
const BlockCenter = styled.div`
  ${boxCenterCss}
  flex: 3;
`;
const BlockRight = styled.div`
  ${boxCenterCss}
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 11px;
  gap: 15px;
`;
const ButtonArrow = styled.button`
  width: 15%;
`;
const ButtonIcon = styled.button`
  padding: 0;
`;
const ButtonAnswerMeets = styled.button<{ unansweredCount: boolean }>`
  ${boxCenterCss}
  height: 30px;
  padding: 0 7px;
  border-radius: 51px;
  gap: 4px;
  background-color: ${Colors.LIGHT.lighGrey};
  cursor: default;
  ${({ unansweredCount }) =>
    unansweredCount &&
    `
  background-color: ${Colors.LIGHT.lightRed};
  cursor: pointer;
`}
`;
