import React, { FC, useMemo, useState } from "react";

import { FormattedMessage } from "react-intl";
import Toggle from "react-toggle";
import styled from "styled-components";

import { ModalRenderWithCondition } from "@hoc";
import { WorkDay } from "@interfaces/businessGoals.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { Notification } from "@ui/notification/Notification";

import { ReactComponent as SvgTimeClock } from "../../../../assets/timeClock.svg";
import { CommonFields } from "../types";

interface IProps extends CommonFields {
  timeEnabled: boolean;
  workDays: WorkDay[];
}

export const TimeNotDay: FC<IProps> = ({ handleData, timeEnabled, workDays }) => {
  const [isVisibleRemoveTime, setVisibleRemoveTime] = useState(false);

  const handleSwitch = () => {
    if (timeEnabled && isTimeWorkDays) {
      setVisibleRemoveTime(true);
    }
    handleData("timeEnabled", !timeEnabled);
  };

  const handleRemoveAllWorkDays = () => {
    const days = [...workDays].map((i) => ({ ...i, startTime: null, endTime: null }));
    handleData("workDays", days);
    setVisibleRemoveTime(false);
  };

  const handleCancelRemoveWorkDays = () => {
    handleData("timeEnabled", true);
    setVisibleRemoveTime(false);
  };

  const isTimeWorkDays = useMemo(() => workDays.some((workDay) => workDay.startTime && workDay.endTime), [workDays]);

  return (
    <ContentWrap>
      <BilletItem>
        <TextWrap>
          <SvgTimeClock />
          <TextFont type="bold" weight="700" size={16}>
            <FormattedMessage id="taskTimeSet" />
          </TextFont>
        </TextWrap>
        <Toggle checked={timeEnabled} onChange={handleSwitch} icons={false} />
      </BilletItem>

      <ModalRenderWithCondition condition={isVisibleRemoveTime}>
        <Notification
          phraseId="timeSetAlert"
          phraseOkId="yes"
          type="confirm"
          onOk={handleRemoveAllWorkDays}
          onCancel={handleCancelRemoveWorkDays}
        />
      </ModalRenderWithCondition>
    </ContentWrap>
  );
};

const ContentWrap = styled.section`
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
`;
const BilletItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
  margin-top: 0;
  width: auto;
  border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
`;
const TextWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
