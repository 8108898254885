import React, { Dispatch, FC, SetStateAction } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgCheckList } from "@assets/checklist.svg";
import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { ButtonMain, RoundButton, Spacer } from "@styles/styles";
import { Colors } from "@theme/colors";
import { ModalUI, TextFont } from "@ui";

interface IAddWorkDaysReminder {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  setIsVisibleAddDay: Dispatch<SetStateAction<boolean>>;
}

export const AddWorkDaysReminder: FC<IAddWorkDaysReminder> = ({ isVisible, setIsVisible, setIsVisibleAddDay }) => {
  const handleAddDays = () => {
    setIsVisible(false);
    setIsVisibleAddDay(true);
  };

  return (
    <ModalUI isVisible={isVisible} onClose={() => setIsVisible(false)}>
      <ContentWrap>
        <RoundButton top={10} onClick={() => setIsVisible(false)}>
          <SvgClose />
        </RoundButton>
        <Content>
          <SvgCheckList />
          <Spacer px={20} />
          <TextFont size={18} align="center">
            <FormattedMessage id="taskWorkDaysAddDescription" defaultMessage={"Не забудьте распланировать работу над задачей "} />
          </TextFont>

          <ButtonMainAdd onClick={handleAddDays}>
            <TextFont size={18} type="bold" weight="700" color={Colors.LIGHT.white}>
              <FormattedMessage id="taskWorkDaysAdd" defaultMessage={"Добавить дни работы"} />
            </TextFont>
          </ButtonMainAdd>

          <button onClick={() => setIsVisible(false)}>
            <TextFont size={18} type="regular" weight="400" color={Colors.LIGHT.green}>
              <FormattedMessage id="planLater" defaultMessage={"Планировать позже"} />
            </TextFont>
          </button>
        </Content>
      </ContentWrap>
    </ModalUI>
  );
};

const ContentWrap = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 0 16px 20px 16px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 70px;
`;
const ButtonMainAdd = styled(ButtonMain)`
  width: 100%;
  margin-top: 46px;
  margin-bottom: 30px;
  height: 56px;
`;
