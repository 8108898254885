import React, { FC, useMemo, useCallback } from "react";

import dayjs from "dayjs";
import { isNull } from "lodash";
import { FormattedMessage } from "react-intl";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { ReactComponent as SvgMessage } from "@assets/message.svg";
import { ReactComponent as SvgPeopleWrapper } from "@assets/people_wrapper.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { IUnitedGroupItem } from "@components/screens/profile/components/groups/Groups";
import { RenderWithCondition } from "@hoc";
import { IParticipantAttendance } from "@interfaces/eventsOfDay.interface";
import { IMeetParticipants, TTypeParticipants, IMeet } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { LetterAvatar } from "@screens/day/components/timetable/main/modal/components/participants/components/LetterAvatar";
import { IExternalUser } from "@services/meet.service";
import { useSetParticipantStatusCommentAsViewedMutation } from "@services/meetApi";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { AssistantToast, TextFont } from "@ui";
import { toFormatDate } from "@utils/toFormatTime";
import { isInnerMeetParticipant } from "@utils/userTypeChecks";

import { ReactComponent as SvgAttend } from "../../assets/attend.svg";
import { ReactComponent as SvgMayBe } from "../../assets/maybe.svg";
import { ReactComponent as SvgNotAnswer } from "../../assets/not_answer.svg";
import { ReactComponent as SvgNotAttend } from "../../assets/not_attend.svg";
import { ReactComponent as SvgOwner } from "../../assets/owner.svg";

import {
  AllGroupItem,
  BlockAvatars,
  BlockIconRemove,
  BlockText,
  ButtonItem,
  Content,
  ContentWrap,
  IconStatusWrap,
  IconWrap,
  TextTitleMeet,
  TextUserName,
  TextUserWorkPosition,
  ToastWrap,
  TooltipWrap,
} from "./styled";

const iconStatus: Record<TTypeParticipants, JSX.Element> = {
  OWNER: <SvgOwner />,
  ATTENDER: <SvgAttend />,
  NON_ATTENDER: <SvgNotAttend />,
  MAYBE_ATTENDER: <SvgMayBe />,
};

interface IProps {
  user: IMeetParticipants | IExternalUser | Partial<IUnitedGroupItem>;
  isExternal?: boolean;
  isSave: boolean;
  handleDeleteItem: (id: string, isExternal: boolean, isGroupAll?: boolean) => void;
  getUser: (userId: string) => IMeetParticipants | IStateUser | undefined;
  setSelectUser: React.Dispatch<React.SetStateAction<IStateUser | undefined>>;
  assistant?: Partial<IStateUser> | null;
  isUserHasEditPermissions?: boolean;
  isModalList?: boolean;
  meet: IMeet;
  participantsAttendance: IParticipantAttendance[] | null;
  isExistMeet?: boolean;
  index?: number;
}

export const ParticipantsListItem: FC<IProps> = ({
  user,
  index,
  isSave,
  isExternal = false,
  getUser,
  handleDeleteItem,
  setSelectUser,
  assistant,
  isUserHasEditPermissions = false,
  isModalList = false,
  meet,
  participantsAttendance,
  isExistMeet = false,
}) => {
  const [setParticipantStatusCommentAsViewed] = useSetParticipantStatusCommentAsViewedMutation();
  const participant = getUser((user as IMeetParticipants).userId) as IStateUser;
  const isInner = isInnerMeetParticipant(user);

  const { currentUser, directorId } = userSelector();

  const isFirstElement = index === 0;
  const isItemDeleteAllowed = useMemo(() => {
    const itemId = (user as IMeetParticipants)?.user?.id ?? user.id;
    const isMatchedIds = directorId ? true : currentUser?.id !== itemId;

    return isUserHasEditPermissions && (user as IMeetParticipants).status !== "OWNER" && isMatchedIds;
  }, [user, isUserHasEditPermissions, currentUser, directorId]);

  const isNotFired = useMemo(() => user?.user?.active === true || user?.user?.active === undefined, [user]);
  const currentUserName = useMemo(
    () =>
      `${(user?.user?.lastName && user?.user?.lastName[0]?.toUpperCase()) ?? ""}
      ${(user?.user?.firstName && user?.user?.firstName[0]?.toUpperCase()) ?? ""}`,
    [user],
  );

  const borderColorStatus = useCallback(
    (item: IMeetParticipants) => {
      const busyStatus = getUser(item.userId)?.busy;

      if (isNull(busyStatus)) {
        return Colors.DARK.grey;
      }

      if (!isSave) {
        const attendance = participantsAttendance?.filter((i) => i.userId === item.userId)[0];
        return attendance?.meetings.length ? Colors.LIGHT.red : Colors.LIGHT.green;
      }

      return getUser(item.userId)?.busy ? Colors.LIGHT.red : Colors.LIGHT.green;
    },
    [isSave, participantsAttendance],
  );

  const clickHandler = (uid: string) => {
    if (!isExternal) {
      setSelectUser(getUser(uid) as IStateUser | undefined);
    }
  };

  const handleHoverComment = (val: boolean) => {
    if (val) {
      if (!(user as IMeetParticipants).statusCommentIsViewed) {
        setParticipantStatusCommentAsViewed({
          id: meet.id,
          date: toFormatDate(dayjs(meet.startTime)),
          userId: user.userId,
          repeat: !!meet.repeat,
        });
      }
    }
  };

  const sizes = {
    icon: isModalList ? 32 : 38,
    button: isModalList ? 50 : 58,
  };

  const isGroupAll = useMemo(() => user?.type === "ALL_IN_SPACE", [user, meet]);
  const isGroupAllSaved = useMemo(() => meet?.presetGroups?.[0]?.type === "ALL_IN_SPACE", [user, meet]);

  const textUserName = useMemo(() => {
    if (isGroupAll) {
      return `Выбрана  группа "${(user as IUnitedGroupItem).name}"`;
    }

    return isExternal
      ? `${(user as IExternalUser)?.firstName} ${(user as IExternalUser)?.lastName}`
      : `${participant?.lastName ?? (participant as unknown as IMeetParticipants)?.user?.lastName ?? ""} ${
          participant?.firstName ?? (participant as unknown as IMeetParticipants)?.user?.firstName ?? ""
        }`;
  }, [user, isExternal, isGroupAll, participant]);

  const ReactTooltipCustom = {
    backgroundColor: Colors.LIGHT.white,
    borderRadius: 6,
    padding: 10,
    boxShadow: ` 0px 2px 6px 0px ${Colors.LIGHT.shadowTooltip}`,
    zIndex: 999,
    width: 232,
  };

  const renderIconStatus = (user: IMeetParticipants): JSX.Element => {
    if (meet.externalId && meet.externalOwnerUserId === user.id) return iconStatus.OWNER;
    if (user.email) return null;
    return iconStatus[user.status] ?? <SvgNotAnswer />;
  };

  return (
    <>
      <ButtonItem key={user.userId} height={sizes.button} isModalList={isModalList} onClick={() => clickHandler(user.userId)}>
        <ContentWrap>
          <BlockAvatars>
            <RenderWithCondition condition={isExternal && !isGroupAll}>
              <>
                <LetterAvatar
                  userName={(user as IExternalUser)?.firstName}
                  lastName={(user as IExternalUser)?.lastName}
                  style={{ backgroundColor: Colors.DARK.asphalt }}
                  textStyle={{ color: Colors.LIGHT.white }}
                />
                <IconStatusWrap>{renderIconStatus(user)}</IconStatusWrap>
              </>
            </RenderWithCondition>
            <RenderWithCondition condition={isGroupAll}>
              <IconWrap>
                <SvgPeopleWrapper width={37} height={37} fill={Colors.LIGHT.orange} />
              </IconWrap>
            </RenderWithCondition>
            <RenderWithCondition condition={!isExternal && !user?.name}>
              <>
                <RenderWithCondition condition={!isNotFired}>
                  <LetterAvatar
                    style={{ backgroundColor: Colors.DARK.asphalt }}
                    userName={currentUserName}
                    lastName={user?.user?.firstName}
                    colorText={Colors.LIGHT.white}
                  />
                </RenderWithCondition>
                <RenderWithCondition condition={isNotFired}>
                  <>
                    <UserAvatar
                      avatarFileId={participant?.avatarFileId ?? participant?.user?.avatarFileId ?? null}
                      style={{
                        width: sizes.icon,
                        height: sizes.icon,
                        borderRadius: sizes.icon,
                        marginRight: 8,
                        ...(!user.status && { border: `2px solid ${borderColorStatus(user)}` }),
                      }}
                    />
                    <IconStatusWrap>{renderIconStatus(user)}</IconStatusWrap>
                  </>
                </RenderWithCondition>
              </>
            </RenderWithCondition>
          </BlockAvatars>
          <BlockText>
            <TextUserName type="bold" size={16} weight="700" isLineThrough={user?.deleted || !isNotFired}>
              {textUserName}
            </TextUserName>

            <RenderWithCondition condition={isNotFired && !isGroupAll}>
              <TextUserWorkPosition size={14} color={Colors.LIGHT.placeholder.main}>
                {isExternal ? user?.email : participant?.workPosition ?? participant?.user?.workPosition ?? ""}
              </TextUserWorkPosition>
            </RenderWithCondition>
            <RenderWithCondition condition={!isNotFired && !isGroupAll}>
              <TextTitleMeet size={14} color={Colors.LIGHT.placeholder.main}>
                {"Не активен"}
              </TextTitleMeet>
            </RenderWithCondition>
          </BlockText>
        </ContentWrap>
        <BlockIconRemove>
          {isInner && user.status !== "OWNER" && user.statusComment && meet.createdByCurrentUser && (
            <button data-tooltip-id={`tooltip-comment_${user.id}`}>
              <SvgMessage
                fill={(user as IMeetParticipants).statusCommentIsViewed ? Colors.LIGHT.lighGrey : Colors.LIGHT.background.green}
              />
            </button>
          )}
          <RenderWithCondition condition={isItemDeleteAllowed}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteItem(isExternal ? user.id : user.userId, isExternal, isGroupAll);
              }}
            >
              <SvgRemove width={12} height={12} fill={Colors.LIGHT.background.grey} />
            </button>
          </RenderWithCondition>
          <RenderWithCondition condition={isInner && assistant && user.status === "OWNER"}>
            <ToastWrap>
              <AssistantToast assistant={assistant} />
            </ToastWrap>
          </RenderWithCondition>
        </BlockIconRemove>
      </ButtonItem>
      <RenderWithCondition condition={isGroupAllSaved && isExistMeet && isFirstElement}>
        <AllGroupItem height={sizes.button} isModalList={isModalList}>
          <Content>
            <IconWrap>
              <SvgPeopleWrapper width={37} height={37} fill={Colors.LIGHT.orange} />
            </IconWrap>
            <TextFont type="bold" size={16} weight="700">
              {`Выбрана  группа "${meet?.presetGroups?.[0]?.name}"`}
            </TextFont>
          </Content>
        </AllGroupItem>
      </RenderWithCondition>

      {isInner && user.statusComment && (
        <ReactTooltip id={`tooltip-comment_${user.id}`} style={ReactTooltipCustom} place="bottom-start" setIsOpen={handleHoverComment}>
          <TooltipWrap>
            <TextFont size={16} weight="700" type="bold">
              <FormattedMessage id="comment" />
            </TextFont>
            <TextFont weight="400" size={16}>
              {user.statusComment}
            </TextFont>
          </TooltipWrap>
        </ReactTooltip>
      )}
    </>
  );
};
