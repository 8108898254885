import React, { FC, useEffect, useState, lazy, Suspense } from "react";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as Back } from "@assets/back.svg";
import { ReactComponent as SvgNotAnswerMeets } from "@assets/three_persons.svg";
import { AsyncStorageKeys } from "@constants/asyncStorageKeys";
import { RenderWithCondition } from "@hoc";
import { SwitchShowPersonalTasks } from "@screens/day/components/header/modal/SwitchShowPersonalTasks";
import { useGetUnansweredCountQuery } from "@services/meetApi";
import { SelectRole } from "@shared";
import { TypeDisabled } from "@shared/calendar";
import { selectCalendar } from "@store/calendar";
import { setStartWeekDay, setViewMode, setIsShowUnansweredMeets, CalendarViewModes } from "@store/calendar/slice";
import { selectCurrentDate } from "@store/screenDay";
import { clearCurrentEvent, setCurrentEventContext } from "@store/screenDay/slice";
import { selectTheme, themeSelector } from "@store/theme";
import { boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { ModalUI } from "@ui/modal/ModalUI";
import { TextFont } from "@ui/TextFont";

import { WeekMonthSwitcher } from "./WeekMonthSwitcher";

const LazyAppCalendar = lazy(() => import("@shared/calendar/AppCalendar"));

interface IModal {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  press?: (...arg: unknown[]) => void;
  typeDisabled?: TypeDisabled;
  onPressDisabled?: () => void;
  isFullWidth?: boolean;
  isOnlyMonthMode?: boolean;
}

export const Modal: FC<IModal> = ({ isVisible, setIsVisible, press, typeDisabled, onPressDisabled, isFullWidth, isOnlyMonthMode }) => {
  const currentDate = useSelector(selectCurrentDate);
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const theme = selectTheme("extra");
  const { extra } = themeSelector();
  const dispatch = useDispatch();

  const { data: unansweredCount } = useGetUnansweredCountQuery();
  const { isShowUnansweredMeets } = useSelector(selectCalendar);

  const getCalendarViewMode = () => {
    const savedMode = localStorage.getItem(AsyncStorageKeys.CalendarViewMode);

    if (savedMode === CalendarViewModes.Week) {
      if (isOnlyMonthMode) {
        dispatch(setViewMode(CalendarViewModes.Month));
      } else {
        dispatch(setViewMode(CalendarViewModes.Week));
      }
    }
  };

  useEffect(() => {
    getCalendarViewMode();
  }, []);

  useEffect(
    () => () => {
      dispatch(setStartWeekDay(dayjs().startOf("week")));
    },
    [],
  );

  return (
    <ModalUI
      isVisible={isVisible}
      bg={Colors.LIGHT.white}
      onClose={() => setIsVisible(false)}
      isFullWidth={isFullWidth}
      scrollEnable={false}
    >
      <BlockHeader>
        <BlockLeft>
          <ButtonBack
            onClick={() => {
              setIsVisible(false);
              dispatch(setCurrentEventContext(null));
              dispatch(clearCurrentEvent());
            }}
          >
            <Back fill={theme.background.green} />
            <TextFont size={16} color={theme.text.accent}>
              Назад
            </TextFont>
          </ButtonBack>

          <RenderWithCondition condition={extra === "ASSISTANT"}>
            <SelectRole type="view" />
          </RenderWithCondition>
        </BlockLeft>
        <BlockRight>
          {!isOnlyMonthMode && <WeekMonthSwitcher />}
          <SwitchShowPersonalTasks />
          <ButtonItem
            onClick={() => dispatch(setIsShowUnansweredMeets(!isShowUnansweredMeets))}
            disabled={!unansweredCount}
            unansweredCount={!!unansweredCount}
          >
            <SvgNotAnswerMeets fill={unansweredCount ? Colors.LIGHT.red : Colors.LIGHT.grey500} />
            {!!unansweredCount && (
              <TextFont size={14} color={Colors.LIGHT.red}>
                {unansweredCount}
              </TextFont>
            )}
          </ButtonItem>
        </BlockRight>
      </BlockHeader>
      <CalendarWrap>
        <Suspense fallback={<></>}>
          <LazyAppCalendar
            setIsVisible={setIsVisible}
            press={press}
            typeDisabled={typeDisabled}
            onPressDisabled={onPressDisabled}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
          />
        </Suspense>
      </CalendarWrap>
    </ModalUI>
  );
};

const BlockHeader = styled.div`
  ${boxCenterCss};
  justify-content: space-between;
  border-bottom: 0.5px solid ${Colors.LIGHT.disabled};
  padding: 9px 9px 12px;
`;
const BlockLeft = styled.div`
  ${boxCenterCss};
  gap: 10px;
`;
const BlockRight = styled.div`
  ${boxCenterCss};
  gap: 8px;
`;
const CalendarWrap = styled.div`
  height: 92%;
`;
const ButtonBack = styled.button`
  ${boxCenterCss};
  gap: 5px;
`;
const ButtonItem = styled.button<{ unansweredCount: boolean }>`
  ${boxCenterCss};
  height: 30px;
  padding: 0 7px;
  border-radius: 51px;
  gap: 4px;
  background-color: ${Colors.LIGHT.lighGrey};
  cursor: default;
  ${({ unansweredCount }) =>
    unansweredCount &&
    `
    background-color: ${Colors.LIGHT.lightRed};
    cursor: pointer;
  `}
`;
