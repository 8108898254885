import React, { memo, useRef, useCallback, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgCancelList } from "@assets/cancel-list.svg";
import { regExpHyperLink, regExpIsHTML } from "@constants/regExp";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { IFile } from "@interfaces/files.interfaces";
import { IMeet } from "@interfaces/meet.interface";
import { Description } from "@screens/day/components/ListTasks/Modal/components/viewSave/components/Description";
import { MeetingTasks } from "@screens/day/components/timetable/main/modal/components/meetingTasks/MeetingTasks";
import { AttendanceConfirmation } from "@screens/day/components/timetable/main/modal/components/viewSave/AttendanceConfirmation";
import { useAcceptOwnerStatusMutation, useRemoveFromSheduleMutation, useUpdateSingleOrSerieMeetingMutation } from "@services/meetApi";
import { FileAttachments } from "@shared";
import { EventComments } from "@shared";
import { EventCommentsTypes } from "@shared/eventComments/EventComments";
import { FileUploadStatuses } from "@shared/fileAttachments/useFileAttachments";
import { removeFilesFromServer } from "@shared/fileAttachments/utils";
import { selectCurrentDate } from "@store/screenDay";
import { setEventToReopen } from "@store/screenDay/slice";
import { selectTheme } from "@store/theme";
import { selectUserPermissoins, userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { TextFont, HeaderModal, Billet } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { arePropsEqual } from "@utils/arePropsEqual";
import { toFormatHHmm } from "@utils/toFormatTime";

import { Participants, Placement } from "..";
import { TKindChangeMeet } from "../../Modal";
import { useRoleModel } from "../accessRights/useRoleModel";
import { ActivityParticipants } from "../activityParticipants/ActivityParticipants";
import { AllowPush } from "../allowPush/AllowPush";
import { TStringUnknownTupleArray } from "../utils";
import { renderResult } from "../viewNotSave/components/repeat/utils";

import { ViewSaveHeader, Actions } from "./components";
import { Menu } from "./menu/Menu";
import { ModalDelete } from "./modalDelete";
import { tabs } from "./modalDelete/constants";

interface IViewSave {
  meet: IMeet;
  attachedTasks: ITask[];
  selectedTaskIds: string[];
  isEditMode: boolean;
  uploadedFiles: IFile[];
  allFiles: IFile[];
  fileUploadStatus: FileUploadStatuses;
  handleClose: () => void;
  setIsSave: (arg: boolean) => void;
  setKindChangeMeet: (arg: TKindChangeMeet) => void;
  attachTasksToMeeting: (meet: IMeet, isModal: boolean) => void;
  resetTaskIdsToAttach: () => void;
  handleAttachTasks: (tasks: ITask[], isHotUpdate: boolean) => void;
  onTaskDelete: (id: string, onlyRemoveFromList?: boolean) => void;
  openFilePicker: (params?: { closeOverModal?: () => void }) => void;
  deleteFile: (id: string) => void;
  handleFileDownload: (id: string, fileName: string) => void;
  resetUploadedFiles: () => void;
  removeUploadedFilesFromServer: () => void;
  resetFileIdsToRemove: () => void;
  isModal: boolean;
  isRenderDotes: boolean;
  handleFileChange: (files: FileList) => void;
}

enum ChangeEvents {
  TaskAdd = "TaskAdd",
  TaskRemove = "TaskRemove",
  FileAdd = "FileAdd",
  FileRemove = "FileRemove",
}

export const ViewSave = memo(
  ({
    meet,
    attachedTasks,
    isEditMode,
    uploadedFiles,
    allFiles,
    fileUploadStatus,
    handleClose,
    setIsSave,
    setKindChangeMeet,
    resetTaskIdsToAttach,
    handleAttachTasks,
    onTaskDelete,
    openFilePicker,
    deleteFile,
    handleFileDownload,
    resetUploadedFiles,
    resetFileIdsToRemove,
    isModal,
    isRenderDotes,
    handleFileChange,
  }: IViewSave) => {
    const dispatch = useDispatch();
    const { isUserHasEditPermissions, isUserHasInvitePermissions } = useRoleModel(meet);

    const [updateSingleMeetOrSerie] = useUpdateSingleOrSerieMeetingMutation();
    const [removeFromShedule] = useRemoveFromSheduleMutation();
    const [acceptOwnerStatus] = useAcceptOwnerStatusMutation();

    const meetChangeMode = useRef<"serie" | "event">();
    const changeEvent = useRef<ChangeEvents>();
    const updationBody = useRef<object>();
    const fileIdToRemove = useRef<string | null>(null);

    const [isVisibleActions, setIsVisibleActions] = useState(false);
    const [isChangeModeSelectorVisible, setIsChangeModeSelectorVisible] = useState(false);
    const [openModalFiredOwner, setOpenModalFiredOwner] = useState(false);
    const [isVisibleEdit, setIsVisibleEdit] = useState(false);
    const [isVisibleActivity, setIsVisibleActivity] = useState(false);

    const selectedDay = useSelector(selectCurrentDate);
    const theme = selectTheme("extra");
    const { currentUser, directorId } = userSelector();
    const permissions = useSelector(selectUserPermissoins);

    const isGroupAllSaved = meet?.presetGroups?.length && meet?.presetGroups[0].type === "ALL_IN_SPACE";

    const isSingleEvent = useMemo(() => !meet.repeat && !meet.parentEvent, [meet.repeat, meet.parentEvent]);

    const statusParticipant = useMemo(
      () => meet.participants?.filter((i) => i.userId === (directorId ? directorId : currentUser?.id))[0],
      [currentUser?.id, directorId, meet.participants],
    );

    const renderDate = useMemo(() => {
      const day = dayjs(meet.startTime).format("DD MMMM");
      return `${day}, ${toFormatHHmm(dayjs(meet.startTime))}-${toFormatHHmm(dayjs(meet.endTime))}`;
    }, [meet.endTime, meet.startTime]);

    const handleEdit = (isSerie?: boolean) => {
      if (isSerie) {
        setKindChangeMeet("serie");
      } else {
        setKindChangeMeet("event");
      }
      setIsSave(false);
    };

    const handleCloseWrapper = () => {
      dispatch(setEventToReopen(null));
      handleClose();
    };

    const handleSaveChanges = async () => {
      if (!meet) return;
      if (!updationBody.current) return;

      resetUploadedFiles();

      let targetEventId = meet.id;

      if (meetChangeMode.current === "serie" && meet?.parentEvent?.id && !meet.repeat) {
        targetEventId = meet?.parentEvent?.id;
      }

      const response = await updateSingleMeetOrSerie({
        id: targetEventId,
        data: updationBody.current,
        date: dayjs(selectedDay).format("YYYY-MM-DD"),
        repeat: Boolean(meet?.repeat),
        changeSerie: meetChangeMode.current === "serie",
        parentEvent: meet.parentEvent,
      }).unwrap();

      if (response) {
        const isChnageSerie = changeEvent.current === ChangeEvents.FileRemove && meetChangeMode.current === "serie";

        if ((isChnageSerie || isSingleEvent) && fileIdToRemove.current) {
          removeFilesFromServer([fileIdToRemove.current]);
          fileIdToRemove.current = null;
          resetFileIdsToRemove();
        }
      }

      if (meet.parentEvent && changeEvent.current === ChangeEvents.TaskRemove && meetChangeMode.current === "serie") {
        updateSingleMeetOrSerie({
          id: meet.id,
          data: updationBody.current,
          date: dayjs(selectedDay).format("YYYY-MM-DD"),
          repeat: Boolean(meet?.repeat),
          changeSerie: false,
          parentEvent: meet.parentEvent,
        });
      }

      resetTaskIdsToAttach();
    };

    const deleteFileWrapper = (id: string) => {
      deleteFile(id);

      changeEvent.current = ChangeEvents.FileRemove;
      fileIdToRemove.current = id;
      updationBody.current = { fileIds: [...allFiles.map((file) => file.id).filter((fileId) => fileId !== id)] };

      if (isSingleEvent) {
        handleSaveChanges();
        return;
      }

      setIsChangeModeSelectorVisible(true);
    };

    const getAttachedTaskUpdation = useCallback(
      (ids: string[], excludeId?: string) => {
        let result = [];

        if (excludeId) {
          result = [...ids, ...(meet?.attachedTaskIds ?? [])].filter((id) => id !== excludeId);
        } else {
          result = [...ids, ...(meet?.attachedTaskIds ?? [])];
        }

        return {
          attachedTaskIds: result,
        };
      },
      [meet.attachedTaskIds],
    );

    const handleAttachTaskEdit = useCallback(
      (tasks: ITask[] = [], excludeId?: string) => {
        updationBody.current = getAttachedTaskUpdation(
          [...attachedTasks.map((task) => task.id), ...(tasks ? tasks.map((item) => item.id) : [])],
          excludeId,
        );

        if (excludeId) {
          changeEvent.current = ChangeEvents.TaskRemove;
          onTaskDelete(excludeId, true);
        } else {
          changeEvent.current = ChangeEvents.TaskAdd;
          handleAttachTasks(tasks, true);
        }

        if (isSingleEvent) {
          handleSaveChanges();
          return;
        }

        setIsChangeModeSelectorVisible(true);
      },
      [isSingleEvent, attachedTasks, onTaskDelete, handleAttachTasks],
    );

    const handleRemoveFromShedule = async () => {
      removeFromShedule({ id: meet.id, date: dayjs(selectedDay).format("YYYY-MM-DD"), repeat: !!meet.repeat });
      handleClose();
    };

    const handlePlacements = (name: string, val: unknown) => {
      updationBody.current = { [name]: val };
      changeEvent.current = undefined;

      if (meet.repeat || meet.parentEvent) {
        setIsChangeModeSelectorVisible(true);
      }
      if (isSingleEvent) {
        return handleSaveChanges();
      }
    };

    const handleUserChoice = (mode: "serie" | "event") => {
      meetChangeMode.current = mode;
      setIsChangeModeSelectorVisible(false);
      handleSaveChanges();
    };

    const handleAcceptOwnerStatus = useCallback((id: string, userId: string) => {
      setOpenModalFiredOwner(false);
      acceptOwnerStatus({ id, userId, isSerie: Boolean(meet.repeat), isException: Boolean(meet.parentEvent) });
    }, []);

    const getFileAddUpdationBody = () => ({ fileIds: [...uploadedFiles.map((file) => file.id), ...meet.files.map((item) => item.id)] });

    const isOnlyOwner = useMemo(
      () => meet.participants?.length === 1 && meet.participants[0].status === "OWNER" && !meet.externalUsers?.length,
      [meet.participants, meet.externalUsers],
    );

    const repeat = useMemo(() => (meet.parentEvent ? meet.parentEvent?.repeat : meet.repeat), [meet.parentEvent, meet.repeat]);

    const isEditFiredAuthor = useMemo(() => {
      const isFiredOwner = !meet?.participants?.filter((item) => item.status === "OWNER")[0]?.user?.active;
      const isManyParticipants = meet?.participants?.length > 2;
      const isFutureMeet = dayjs(meet?.startTime).isAfter(dayjs());

      return Boolean(isFiredOwner && isManyParticipants && isFutureMeet && meet?.active);
    }, [meet?.participants, meet?.active, meet?.startTime]);

    const closeMenu = useCallback(() => setIsVisibleActions(false), []);

    useEffect(() => {
      if (uploadedFiles.length && fileUploadStatus === FileUploadStatuses.UploadSuccess && !isEditMode) {
        updationBody.current = getFileAddUpdationBody();

        if (isSingleEvent) {
          handleSaveChanges();
          return;
        }

        setIsChangeModeSelectorVisible(true);
      }
    }, [fileUploadStatus, uploadedFiles, isEditMode, isSingleEvent]);

    useEffect(() => {
      if (isEditFiredAuthor) {
        setOpenModalFiredOwner(true);
        return;
      }
      setOpenModalFiredOwner(false);
    }, [isEditFiredAuthor]);

    const currentActivePush = useMemo(
      () =>
        isGroupAllSaved
          ? false
          : !meet.notificationDisableSettings?.filter((item) => item?.notificationName === "CONFIRM_MEETING_PARTICIPANT_STATUS")[0]
              ?.notificationTypes?.length,
      [isGroupAllSaved, meet.notificationDisableSettings],
    );

    const handlePressThreeDotes = useCallback(() => {
      setIsVisibleActions(true);
    }, []);

    const participantStatusComment = useMemo(
      () => meet.participants?.filter((i) => i.userId === (directorId ? directorId : currentUser?.id))[0]?.statusComment ?? "",
      [currentUser?.id, directorId, meet.participants],
    );

    const description = useMemo(() => {
      if (regExpIsHTML.test(meet.description)) {
        return meet.description
          .replace(/(<\/?(html|head|meta|body)[^<]*>)/gi, "") // delete unnecessary tags
          .replace(/style="[^>]+/gi, "") // delete all styles
          .replace(regExpHyperLink, "<a href='$&' class='autoLink' target='_blank'>$&</a>") // convert hyper links to html format
          .trim();
      }
      return meet.description;
    }, [meet.description]);

    return (
      <StContentWrapSection>
        <RenderWithCondition condition={isModal}>
          <HeaderModal
            title="meeting"
            leftSide={{ onPressClose: handleCloseWrapper, isHideCancel: true }}
            themeTextColor={theme.text.main}
            styleContainer={{ backgroundColor: theme.background.main }}
          />
        </RenderWithCondition>

        <StContentDiv>
          <RenderWithCondition condition={!meet.active && !meet.createdByCurrentUser}>
            <StButtonRemoveBtn onClick={handleRemoveFromShedule}>
              <SvgCancelList />
              <TextFont size={18} color={Colors.LIGHT.red}>
                <FormattedMessage id="removeCal" />
              </TextFont>
            </StButtonRemoveBtn>
          </RenderWithCondition>
          <StTitleBlockDiv>
            <ViewSaveHeader
              statusParticipant={statusParticipant}
              meet={meet}
              pressDotes={handlePressThreeDotes}
              isRenderDotes={isRenderDotes}
            />
            <TextFont type="bold" size={24} color={Colors.LIGHT.white} weight="700" style={{ marginBottom: 5 }}>
              {meet?.tag?.name && `#${meet?.tag?.name}`} {meet.name}
            </TextFont>
            <Description text={description} />
          </StTitleBlockDiv>
          <StMainDiv addPadding={meet.currentParticipantStatus === null}>
            <TextFont type="bold" size={18} weight="700">
              {renderDate}
            </TextFont>
            <RenderWithCondition condition={repeat}>
              <TextFont size={18}>
                {renderResult({
                  type: repeat?.type,
                  endTime: repeat?.endTime,
                  days: repeat?.dayOfWeeks,
                  daysRepeat: repeat?.days,
                  period: repeat?.repetitionPeriod,
                })}
              </TextFont>
            </RenderWithCondition>

            <RenderWithCondition condition={isUserHasEditPermissions || meet?.places?.length || meet?.meetingRoom}>
              <Placement
                places={meet.places}
                meet={meet}
                hotUpdate
                allowEdit={isUserHasEditPermissions && !meet.externalId}
                handleData={handlePlacements}
                isButtonAdd={false}
                startData={{
                  data: meet,
                  startTime: meet.startTime ?? "",
                  endTime: meet.endTime ?? "",
                  handleData: () => undefined,
                  handleTime: () => undefined,
                }}
              />
            </RenderWithCondition>

            <RenderWithCondition condition={(permissions.CAN_ATTEND_MEETING && !isOnlyOwner) || isGroupAllSaved}>
              <>
                <Participants
                  data={meet}
                  isSave={true}
                  currentUserAttendanceStatus={meet.currentParticipantStatus}
                  participantsAttendance={null}
                  isExistMeet={isGroupAllSaved}
                />
                <RenderWithCondition
                  condition={
                    Number((meet?.participants?.length ?? 0) + meet?.externalUsers?.length) > 1 &&
                    (isUserHasEditPermissions || isUserHasInvitePermissions) &&
                    !meet.externalId
                  }
                >
                  <RenderWithCondition condition={!isGroupAllSaved}>
                    <StBilletWrapDiv>
                      <Billet
                        textId="membersEmployment"
                        onPress={() => setIsVisibleActivity(true)}
                        styleText={{ color: theme.text.accent, fontWeight: "400" }}
                      />
                    </StBilletWrapDiv>
                  </RenderWithCondition>
                </RenderWithCondition>
              </>
            </RenderWithCondition>

            <RenderWithCondition
              condition={meet.currentParticipantStatus !== "OWNER" && meet.active && !isGroupAllSaved && !meet.externalId}
            >
              <AttendanceConfirmation
                participantStatusComment={participantStatusComment}
                currentUserAttendanceStatus={meet.currentParticipantStatus}
                isSerieOrException={Boolean(meet.parentEvent || meet.repeat)}
                isSeries={Boolean(meet?.repeat)}
                meetId={meet.id}
                parentId={meet.currentParticipantStatus ? meet.parentEvent?.id : undefined}
                isModal={isModal}
              />
            </RenderWithCondition>

            <RenderWithCondition condition={!meet.externalId}>
              <MeetingTasks
                allowTaskAddition={isUserHasEditPermissions}
                allowTaskDeletion={isUserHasEditPermissions}
                attachedTasks={attachedTasks}
                handleAttachTasks={(tasks) => handleAttachTaskEdit(tasks)}
                onTaskDelete={(id) => handleAttachTaskEdit([], id)}
              />
            </RenderWithCondition>

            <RenderWithCondition condition={meet.createdByCurrentUser || meet.currentParticipantStatus === "OWNER"}>
              <StSwitchWrapDiv>
                {/* //TODO: =========== Temporary Hidden ========== */}
                {/* <RemindMeetings
                  handleData={() => undefined}
                  durations={meet?.notificationDurations}
                  types={meet?.notificationTypes}
                  meet={meet}
                /> */}
                <AllowPush currentActivePush={currentActivePush} />
              </StSwitchWrapDiv>
            </RenderWithCondition>

            <RenderWithCondition condition={!meet.externalId}>
              <FileAttachments
                enableAddButton={false}
                openFilePicker={openFilePicker}
                allowFilePick={isUserHasEditPermissions}
                allowFileDelete={isUserHasEditPermissions}
                allFiles={allFiles}
                uploadedFiles={uploadedFiles}
                handleFileDownload={handleFileDownload}
                deleteFile={deleteFileWrapper}
                handleFileChange={handleFileChange}
              />
            </RenderWithCondition>

            <RenderWithCondition condition={!meet.externalId}>
              <EventComments eventType={EventCommentsTypes.Meeting} eventId={meet.id} comments={meet.meetingComments ?? []} />
            </RenderWithCondition>

            <RenderWithCondition condition={statusParticipant?.meetingStatus}>
              <Actions status={statusParticipant?.meetingStatus} id={meet.id} />
            </RenderWithCondition>
          </StMainDiv>
        </StContentDiv>

        <ModalRenderWithCondition condition={isChangeModeSelectorVisible}>
          <NotificationConfirm
            phraseId="oneMeetOrAll"
            phraseOkId="allSeries"
            phraseCancelId="oneMeet"
            onOk={() => handleUserChoice("serie")}
            onCancel={() => handleUserChoice("event")}
            shouldCloseOnOverlayClick={false}
            isOpen={isChangeModeSelectorVisible}
          />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={openModalFiredOwner}>
          <NotificationConfirm
            phraseId="authorFired"
            subPhraseId="offerToBeOrganizer"
            phraseOkId="yes"
            phraseCancelId="backNo"
            onOk={() => handleAcceptOwnerStatus(meet.id, currentUser.id)}
            onCancel={() => setOpenModalFiredOwner(false)}
            shouldCloseOnOverlayClick={false}
            isOpen={openModalFiredOwner}
          />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isVisibleEdit}>
          <ModalDelete
            isEdit
            close={() => setIsVisibleEdit(false)}
            deleteSerie={() => handleEdit(true)}
            deleteFromSerie={() => handleEdit()}
            data={tabs}
          />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isVisibleActions}>
          <Menu
            meet={meet}
            repeat={repeat}
            setIsVisibleEdit={setIsVisibleEdit}
            setIsSave={setIsSave}
            close={closeMenu}
            closeOverModal={handleClose}
            openFilePicker={openFilePicker}
            isEditAllowed={isUserHasEditPermissions}
            isEditFiredAuthor={isEditFiredAuthor}
            componentMeetingTasks={
              <MeetingTasks
                onlyButton
                allowTaskAddition={isUserHasEditPermissions}
                allowTaskDeletion={isUserHasEditPermissions}
                attachedTasks={attachedTasks}
                handleAttachTasks={(ids) => handleAttachTaskEdit(ids)}
                onTaskDelete={(id) => onTaskDelete(id)}
                close3DotMenu={closeMenu}
              />
            }
          />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isVisibleActivity}>
          <ActivityParticipants
            close={() => setIsVisibleActivity(false)}
            isUserHasEditPermissions={isUserHasEditPermissions}
            hotUpdate
            startData={{
              data: meet,
              startTime: meet.startTime ?? "",
              endTime: meet.endTime ?? "",
              handleData: () => undefined,
              handleTime: () => undefined,
            }}
          />
        </ModalRenderWithCondition>
      </StContentWrapSection>
    );
  },
  arePropsEqual,
);

const StContentWrapSection = styled.section`
  height: 100%;
`;
const StContentDiv = styled.div`
  overflow-y: auto;
  height: 92%;
`;
const StBilletWrapDiv = styled.div`
  margin-top: -20px;
`;
const StTitleBlockDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 17px 12px 30px 12px;
  background-color: ${(props) => props.theme.extra.background.darkGreen};
`;

const StButtonRemoveBtn = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: ${(props) => props.theme.extra.lightRed};
  gap: 10px;
`;

const StSwitchWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const StTextNameTFont = styled(TextFont)`
  margin-bottom: 5px;
`;

const StMainDiv = styled.div<{ addPadding: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.extra.background.main};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px 13px 0px 13px;
  margin-top: -15px;
  gap: 30px;
  ${({ addPadding }) =>
    addPadding &&
    `
    padding-bottom: 100px;
   }
  `}
`;
