import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgEyeClosed } from "@assets/eye-close.svg";
import { ReactComponent as SvgEye } from "@assets/eye.svg";
import { RenderWithCondition } from "@hoc";
import { setIsHidePersonalTasks } from "@store/calendar/slice";
import { selectTheme } from "@store/theme";
import { userSelector } from "@store/user";
import { boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

export const SwitchShowPersonalTasks = () => {
  const dispatch = useDispatch();
  const theme = selectTheme("extra");

  const [displayPersonalEvents, setIsDisplayPersonalEvents] = useState(false);
  const [isToggleVisible, setIsToggleVisible] = useState(true);

  const { directorId } = userSelector();

  const handleSwitchChange = () => {
    setIsDisplayPersonalEvents(!displayPersonalEvents);
    dispatch(setIsHidePersonalTasks(!displayPersonalEvents));
  };

  const getUserDisplayPersonalEventsSetting = async () => {
    const isShowPersonals = localStorage.getItem("showPersonal");
    const isVisible = isShowPersonals ? isShowPersonals === "true" : true;

    setIsToggleVisible(isVisible && !directorId);
  };

  useEffect(() => {
    getUserDisplayPersonalEventsSetting();
  }, []);

  return (
    <RenderWithCondition condition={isToggleVisible}>
      <ButtonItem onClick={handleSwitchChange}>
        {displayPersonalEvents ? <SvgEye fill={theme.text.accent} /> : <SvgEyeClosed fill={theme.text.accent} />}
        <TextFont size={16} color={theme.text.accent}>
          <FormattedMessage id="personalEvents" defaultMessage="Личные" />
        </TextFont>
      </ButtonItem>
    </RenderWithCondition>
  );
};

const ButtonItem = styled.button`
  ${boxCenterCss}
  background-color: ${Colors.DARK.lighGrey};
  border-radius: 5px;
  height: 22px;
  padding: 0 14px 0 12px;
`;
