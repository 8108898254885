import React, { FC, memo, MouseEvent, useMemo, useState, useCallback } from "react";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styled from "styled-components";

import MaybeBackgroundImage from "@assets/maybe1.png";
import { ModalMeetStatus } from "@components/shared/calendar/events/ModalMeetStatus";
import useConfigContext from "@components/shared/calendar/hooks/useConfigContext";
import { RenderWithCondition } from "@hoc";
import { IEventOfDaySimplified, TFirstArgPress } from "@interfaces/eventsOfDay.interface";
import { IMeet } from "@interfaces/meet.interface";
import { selectAttachedEvents } from "@store/attachedMeetingTasks/selectors";
import { close, TaskSubtypes, updateTaskId } from "@store/businessTask";
import { get as getTask } from "@store/businessTask";
import { setIsPersonalTask } from "@store/businessTask/slice";
import { selectScreenDay, updateId } from "@store/screenDay";
import { setOpenContextData, setCategoryModalEventId } from "@store/screenDay/slice";
import { Colors } from "@theme/colors";
import { TaskActions, TextFont } from "@ui";
import { arePropsEqual } from "@utils/arePropsEqual";

import { ModalClose } from "../../../ListTasks/components/modalClose";
import { ModalDelegate } from "../../../ListTasks/components/modalDelegate";
import { heightBlockEvent } from "../constants";
import { CategoryModal } from "../modal/components/viewNotSave/category/CategoryModal";

import { configEvent, HEIGHT_ICON } from "./config";

const schema = Colors.LIGHT;

interface IWeekProps {
  disableOffset: boolean;
  weekStyles: boolean;
  showAsStrip: boolean;
  isCompactView: boolean;
  reduceEventTextWidth: boolean;
}
interface IButtonProps {
  top: number;
  height: number;
  width: number;
  left: number;
  isActive: boolean;
  backColor: string;
  zIndex: number;
  colorBorder?: string;
  opacity?: number;
  isLight?: boolean;
  hasBackgoundImage: boolean;
}

interface IProps extends IEventOfDaySimplified {
  hour: number;
  index: number;
  press?: (arg: TFirstArgPress, type: "TASK" | "MEETING", isPersonalTask?: boolean) => void;
  startHour: number;
  columns?: Array<number>;
  deep: number;
  width: number;
  tagName?: string;
  columnsNumber: number;
  weekViewSettings?: IWeekProps;
  isLight?: boolean;
  setToScroll?: (arg: number) => void;
  mouseRightClick?: (e: MouseEvent<HTMLButtonElement>, data: IEventOfDaySimplified) => void;
  externalId: string | null;
  position?: {
    left: number;
    top: number;
  };
}

const WIDTH_TEXT_TIME = 50;

export const Event: FC<IProps> = memo(function Event(event: IProps) {
  const {
    startTime,
    endTime,
    type,
    width,
    tag,
    name,
    hour,
    id,
    press,
    deep,
    columns,
    weekViewSettings = {},
    isLight,
    mouseRightClick,
    position,
    columnsNumber,
  } = event;
  const [isVisibleComment, setVisibleComment] = useState<string | null>(null);
  const { categoryModalEventId } = useSelector(selectScreenDay);
  const { openContextData } = useSelector(selectScreenDay);
  const { contextTask } = useSelector(selectAttachedEvents);

  const { disableOffset = false } = weekViewSettings as IWeekProps;
  const eventStartHour = dayjs(startTime).hour();
  const eventStartMinute = dayjs(startTime).minute();
  const dispatch = useDispatch();

  const pressHandler = () => {
    if (event.type === "MEETING") {
      dispatch(updateId({ id: event.id, modalOpen: true }));
    }

    if (event.type === "TASK") {
      dispatch(updateTaskId(true));
      dispatch(setIsPersonalTask(event.taskType === "PERSONAL"));
      dispatch(getTask({ id: event.taskId, isPersonalTask: false }));
    }
  };

  function getViewObjectFunction() {
    if (isEmpty(weekViewSettings)) {
      return () => handleEventPress();
    }
    return () => pressHandler();
  }

  const { contextConfig, statusToSet } = useConfigContext(event, getViewObjectFunction(), setVisibleComment);
  const isOpenContext = openContextData?.id === event?.id && openContextData?.startTime === event?.startTime && position.top !== 0;

  const diff = dayjs(endTime).diff(dayjs(startTime), "minutes");
  const diffHours = Math.floor(diff / 60);
  const diffMinutes = diff - diffHours * 60;

  const hourBlock = heightBlockEvent * diffHours + heightBlockEvent * (diffMinutes / 60) + Math.round(diffHours * 2.2);

  const offsetTop = useMemo(() => heightBlockEvent * (eventStartMinute / 60), [eventStartMinute, heightBlockEvent]);
  // const widthBlock = useMemo(() => (columns?.length ? width / (12 / columns.length) : width), [columns?.length, width]);
  const widthBlock = useMemo(() => (columns?.length ? width / (12 / columnsNumber) : width), [columns?.length, columnsNumber, width]);

  const calculatedWidthTextTime = disableOffset ? 0 : WIDTH_TEXT_TIME;
  // const offsetLeft = useMemo(
  //   () => (columns?.length ? calculatedWidthTextTime + (width / 12) * (columns[0] - 1) : calculatedWidthTextTime),
  //   [calculatedWidthTextTime, columns, width],
  // );
  const offsetLeft = useMemo(
    () =>
      columns?.length
        ? calculatedWidthTextTime + (width / 12) * ((columns[0] - 1) * (columnsNumber / columns.length))
        : calculatedWidthTextTime,
    [calculatedWidthTextTime, columns, columnsNumber, width],
  );

  const meetNonConfirmedAttendance = useMemo(
    () => event.type === "MEETING" && event?.currentParticipantStatus === null,
    [event?.currentParticipantStatus, event.type],
  );
  const meetWithTypeAll = useMemo(() => !isEmpty(event.presetGroupIds), [event.presetGroupIds]);
  const meetMayBeAttendance = useMemo(
    () => event.type === "MEETING" && event?.currentParticipantStatus === "MAYBE_ATTENDER",
    [event?.currentParticipantStatus, event.type],
  );
  const isPersonalTask = useMemo(() => event.type === "TASK" && event.taskType === TaskSubtypes.Personal, [event]);

  const configColor: { text: string; bg: string; border?: string; opacity?: number; line?: string } = useMemo(() => {
    if (event.userCategory) {
      if (
        event.currentParticipantMeetingStatus === "DID_NOT_TAKE_PLACE" ||
        event.currentParticipantMeetingStatus === "WILL_NOT_TAKE_PLACE"
      ) {
        return { text: event.userCategory.color, bg: `${event.userCategory.color}33`, line: schema.yellow };
      }
      if (!event.active) {
        return { text: event.userCategory.color, bg: `${event.userCategory.color}33`, line: schema.red };
      }
      return {
        text: schema.white,
        bg: event.userCategory.color,
        line: `${schema.white}99`,
        opacity: 1,
      };
    }
    if (event.currentParticipantMeetingStatus === "DID_NOT_TAKE_PLACE" || event.currentParticipantMeetingStatus === "WILL_NOT_TAKE_PLACE")
      return { text: schema.yellow, bg: schema.lightYellow };
    if (!event.active) return { text: schema.red, bg: schema.lightRed };
    if (meetWithTypeAll) return { text: configEvent()[type]?.textColor, bg: configEvent()[type]?.backgroundColor };
    if (meetNonConfirmedAttendance) return { text: schema.text.main, bg: schema.white, border: schema.blue };
    if (meetMayBeAttendance) return { text: schema.white, bg: schema.lightBlue, border: schema.lightBlue };
    if (isPersonalTask) return { text: schema.white, bg: schema.darkPurple, opacity: 1 };
    return { bg: configEvent()[type]?.backgroundColor, text: configEvent()[type]?.textColor };
  }, [
    event.active,
    event.currentParticipantMeetingStatus,
    event.userCategory,
    isPersonalTask,
    meetMayBeAttendance,
    meetNonConfirmedAttendance,
    meetWithTypeAll,
    type,
  ]);

  const isCancelOrNotTakePlace = useMemo(
    () =>
      event.currentParticipantMeetingStatus === "DID_NOT_TAKE_PLACE" ||
      event.currentParticipantMeetingStatus === "WILL_NOT_TAKE_PLACE" ||
      !event.active,
    [event.currentParticipantMeetingStatus, event.active],
  );

  const isEventHasBackgroungImage = useMemo(() => !!event.userCategory && meetMayBeAttendance, [event.userCategory, meetMayBeAttendance]);

  const smallBlockToSize = hourBlock < 14 ? hourBlock : 14;
  const heightOfRow = hourBlock < 14 ? hourBlock - 2 : 12;
  const heightOfTextTag = hourBlock < 13 ? hourBlock : 15;
  const paddingOfRow = hourBlock < HEIGHT_ICON ? 0 : "1%";

  const handleEventPress = useCallback(() => {
    press?.(
      { id: event?.taskId ? event.taskId : id, repeat: Boolean(event?.repeat), date: event.startTime },
      type ?? "MEETING",
      event.taskType === "PERSONAL",
    );
  }, []);

  const closeTask = useCallback((reason: string, comment: string) => {
    dispatch(setOpenContextData(null));
    dispatch(close({ amount: { id: event.taskId, reason, comment }, isPersonalTask }));
  }, []);

  if (hour !== eventStartHour) return null;

  return (
    <>
      <ButtonEvent
        id={isLight && "event_lighting"}
        top={offsetTop + 1}
        height={hourBlock}
        width={widthBlock - deep * 5}
        left={offsetLeft + deep * 5}
        backColor={configColor.bg}
        isActive={openContextData?.id === id}
        zIndex={deep + 1}
        colorBorder={configColor?.border}
        opacity={configColor.opacity}
        isLight={isLight}
        hasBackgoundImage={isEventHasBackgroungImage}
        onClick={handleEventPress}
        disabled={isLight === false}
        onContextMenu={(e) => mouseRightClick && !event.externalId && mouseRightClick(e, event)}
      >
        <RowEvent>
          <LineVertical backColor={configColor.text} />
          <BlockInfo height={heightOfRow} paddingTop={paddingOfRow}>
            {
              configEvent(
                hourBlock < HEIGHT_ICON ? hourBlock : HEIGHT_ICON,
                configColor.text,
                Number(event.numberOfParticipants) > 1 || meetWithTypeAll,
              )[type]?.icon
            }
            <RenderWithCondition condition={tag}>
              <TextTag color={configColor.text} lineHeight={smallBlockToSize} size={smallBlockToSize} weight="700" height={heightOfTextTag}>
                {`${tag?.name} `}
              </TextTag>
            </RenderWithCondition>
            <TextItemName
              color={configColor.text}
              lineHeight={smallBlockToSize}
              size={smallBlockToSize}
              height={heightOfTextTag}
              isLineThrough={isCancelOrNotTakePlace}
            >
              {name}
            </TextItemName>
          </BlockInfo>
        </RowEvent>
      </ButtonEvent>
      <RenderWithCondition condition={isOpenContext && !isVisibleComment}>
        <TaskActions pos={position} configuration={contextConfig} isForMeeting />
      </RenderWithCondition>

      <RenderWithCondition condition={isOpenContext}>
        <ModalMeetStatus
          onClose={() => {
            setVisibleComment(null);
            dispatch(setOpenContextData(null));
          }}
          isVisible={isVisibleComment === "status"}
          onSend={() => {
            dispatch(setOpenContextData(null));
          }}
          statusToSet={statusToSet}
          event={event}
        />
      </RenderWithCondition>

      <RenderWithCondition condition={isOpenContext && isVisibleComment === "close"}>
        <div onClick={(e) => e.stopPropagation()}>
          <ModalClose
            isVisible={isVisibleComment === "close"}
            setIsVisible={() => {
              setVisibleComment(null);
              dispatch(setOpenContextData(null));
            }}
            onSave={closeTask}
          />
        </div>
      </RenderWithCondition>

      <RenderWithCondition condition={isOpenContext && isVisibleComment === "delegate"}>
        <div onClick={(e) => e.stopPropagation()}>
          <ModalDelegate
            close={() => {
              setVisibleComment(null);
              dispatch(setOpenContextData(null));
            }}
            isModal={true}
            task={contextTask}
          />
        </div>
      </RenderWithCondition>

      <RenderWithCondition condition={categoryModalEventId === event.id}>
        <CategoryModal meet={event as unknown as IMeet} setIsVisible={() => dispatch(setCategoryModalEventId(null))} />
      </RenderWithCondition>
    </>
  );
}, arePropsEqual);

const ButtonEvent = styled.button<IButtonProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 7px;
  padding-left: 0;
  opacity: 0.8;
  top: ${(props) => props.top}px;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  left: ${(props) => props.left}px;
  background-color: ${(props) => props.backColor};
  ${(props) =>
    props.isActive &&
    `
    background-color: ${Colors.LIGHT.lighGrey};  
  `}
  background-size: 25px;
  z-index: ${(props) => props.zIndex};

  ${(props) =>
    props.colorBorder &&
    `
    border: 0.7px solid ${props.colorBorder};  
  `}
  ${(props) =>
    props.hasBackgoundImage &&
    `
    background-image: url(${MaybeBackgroundImage});
    background-repeat: repeat;
  `}
  ${(props) =>
    props.opacity &&
    `
    opacity: ${props.opacity};  
  `}
  ${(props) =>
    props.isLight &&
    `
    box-shadow: 0 4px 11px ${Colors.LIGHT.shadowEvent}; 
    border: 1.5px solid ${Colors.LIGHT.blue};
  `}
  ${(props) =>
    props.isLight === false &&
    `
     opacity: 0.3; 
  `}
`;
const RowEvent = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-radius: 7px;
  height: 100%;
`;
const LineVertical = styled.div<{ backColor: string }>`
  height: 99%;
  width: 3px;
  margin: 0.5px 3px 0 0.5px;
  border-bottom-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: ${(props) => props.backColor};
`;
const BlockInfo = styled.div<{ height: number; paddingTop: number | string }>`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  height: ${(props) => props.height}px;
  padding-top: ${(props) => props.paddingTop};
`;
const TextTag = styled(TextFont)<{ height: number }>`
  width: auto;
  margin-left: 2px;
  height: ${(props) => props.height}px;
`;
const TextItemName = styled(TextFont)<{ height: number; isLineThrough: boolean }>`
  flex: 1;
  margin-left: 2px;
  text-overflow: ellipsis;
  height: ${(props) => props.height}px;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap !important;
  ${(props) =>
    props.isLineThrough &&
    `
    text-decoration: line-through;;  
  `}
`;
