import React, { FC, useEffect } from "react";

import { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";
import Toggle from "react-toggle";
import styled from "styled-components";

import { useTime } from "@hooks/useTime";
import { WorkDay } from "@interfaces/businessGoals.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { EditTimerOptions } from "@ui/TimePicker";
import { toFormatStartTime } from "@utils";

import { CommonFields } from "../types";

interface IProps extends CommonFields {
  workDay: WorkDay;
  dateNow: Dayjs;
  workDays: WorkDay[];
  timeEnabled: boolean;
  setTimeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setWorkDays: React.Dispatch<React.SetStateAction<WorkDay[]>>;
}

export const TimeDay: FC<IProps> = ({ workDay, dateNow, workDays, timeEnabled, setTimeEnabled, setWorkDays }) => {
  const { startTime, endTime, handleTime } = useTime({ startValue: workDay?.startTime, endValue: workDay?.endTime });

  useEffect(() => {
    const start = !timeEnabled ? null : workDay?.startTime ?? toFormatStartTime(dateNow).startTime;
    const end = !timeEnabled ? null : workDay?.endTime ?? toFormatStartTime(dateNow).endTime;

    handleTime(EditTimerOptions.StartTime, start);
    handleTime(EditTimerOptions.EndTime, end);
  }, [timeEnabled]);

  useEffect(() => {
    const tempDays = [...workDays];
    const findIndex = tempDays.findIndex((i) => i.date === dateNow.format("YYYY-MM-DD"));

    if (findIndex === -1) return;

    tempDays.splice(findIndex, 1, { ...tempDays[findIndex], startTime, endTime });
    setWorkDays(tempDays);
  }, [startTime, endTime]);

  const onChangeSwitch = () => {
    setTimeEnabled(!timeEnabled);
  };

  return (
    <ContentWrap>
      <BilletItem>
        <TextWrap>
          <TextFont type="bold" weight="700" size={16}>
            <FormattedMessage id="taskTimeSet" />
          </TextFont>
        </TextWrap>
        <Toggle checked={timeEnabled} onChange={onChangeSwitch} icons={false} />
      </BilletItem>
    </ContentWrap>
  );
};

const ContentWrap = styled.section`
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
`;
const BilletItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
  margin-top: 0;
  width: auto;
  border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
`;
const TextWrap = styled.div`
  display: flex;
  align-items: center;
`;
