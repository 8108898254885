import React, { useEffect, useMemo, useRef, useState } from "react";

import InputMask from "react-input-mask";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { resetStore } from "@constants/logic";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "@constants/settings";
import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { IStateUser } from "@interfaces/user.interface";
import { ProfileUserAvatar } from "@screens/profile/components/userInformation";
import { logOut } from "@store/auth/slice";
import { removeCurrentSession } from "@store/session";
import { selectTheme } from "@store/theme";
import { clearUser, deleteUser, selectIsDogmaUser, selectUserPermissoins, userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { ArrowSvg, TextFont } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { logOutUtil } from "@utils";
import { myTracker } from "@utils/myTracker";
import { resetGlobalState } from "@utils/resetGlobalState";
import { secureStore } from "@utils/secureStore";

import { TypeTab } from "../../types";

import { ReactComponent as SvgAdminPanel } from "./assets/admin_panel.svg";
import { ReactComponent as SvgExit } from "./assets/exit.svg";
import { ReactComponent as SvgNotificationBell } from "./assets/notification-bell.svg";
import { ReactComponent as SvgUpdate } from "./assets/update.svg";
import { tabs, tabsAssistant } from "./constants";
import { ModalEditFIO } from "./ModalEditFIO";

interface IHome {
  setActiveTab: (arg: TypeTab) => void;
  user: IStateUser | null;
  isAssistant: boolean;
}

export const Home = ({ setActiveTab, user, isAssistant }: IHome) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { isTablet } = useDimension();
  const theme = selectTheme("extra");

  const [isVisibleExit, setisVisibleExit] = useState(false);
  const [isVisibleEdit, setisVisibleEdit] = useState(false);
  const [isVisibleDelete, setisVisibleDelete] = useState(false);
  const [pointerEvents, setPointerEvents] = useState<any>("auto");

  const permissons = useSelector(selectUserPermissoins);
  const isDogmaUser = useSelector(selectIsDogmaUser);
  const { unreadMessages } = userSelector();

  const handleLogOut = async () => {
    setisVisibleExit(false);
    setPointerEvents("none");

    dispatch(clearUser());
    dispatch(resetStore());

    await dispatch(removeCurrentSession());
    await dispatch(logOut());

    logOutUtil();
    resetGlobalState();
    navigate("/auth");
  };

  const handleDeleteUser = async () => {
    await dispatch(deleteUser());
    dispatch(logOut());
    logOutUtil();
    resetGlobalState();
    navigate("/auth");
  };

  const getAdminUrl = async () => {
    const [access_token, refresh_token] = await Promise.all([secureStore.getValue(ACCESS_TOKEN), secureStore.getValue(REFRESH_TOKEN)]);

    myTracker("OpenDashboardClick");
    const url = new URL(`${process.env.REACT_APP_BASE_URL_ADMIN}/auth`);
    url.searchParams.append("access_token", access_token);
    url.searchParams.append("refresh_token", refresh_token);
    url.searchParams.append("login", user?.login ?? "");
    url.searchParams.append("isWebApp", true);

    return window.open(url.toString());
  };

  const dataFio = useMemo(() => {
    if (!user) return null;

    return {
      lastName: user.lastName,
      firstName: user.firstName,
      middleName: user.middleName,
    };
  }, [user?.firstName, user?.lastName, user?.middleName]);

  const msgExitExeption = useIntl().formatMessage({ id: "exitconfirm" });
  const msgDeleteExeption = useIntl().formatMessage({ id: "deleteProfileAlert" });

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
      <LoaderRenderWithCondition condition={!user} parent={containerRef.current}>
        <div style={{ ...styles.container, pointerEvents }}>
          <div
            style={{ ...styles.profile, position: "relative", marginRight: isTablet ? 0 : 5, backgroundColor: theme.background.darkGreen }}
          >
            <RenderWithCondition condition={!permissons.EDIT_OWN_USER_INFO && !isAssistant}>
              <button style={{ ...styles.absolute, left: 11, position: "absolute" }} onClick={() => setActiveTab("details")}>
                <TextFont style={styles.text}>
                  <FormattedMessage id="detail" />
                </TextFont>
              </button>
            </RenderWithCondition>
            <RenderWithCondition condition={permissons.EDIT_OWN_USER_INFO}>
              <button
                style={{
                  ...styles.absolute,
                  left: 10,
                  width: "100%",
                  height: 45,
                  position: "absolute",
                  top: 30,
                  display: "flex",
                }}
                onClick={() => setisVisibleEdit(true)}
              >
                <TextFont style={styles.text}>
                  <FormattedMessage id="change" />
                </TextFont>
              </button>
            </RenderWithCondition>

            <button style={{ ...styles.absolute, right: 11, position: "absolute" }} onClick={() => setActiveTab("notifications")}>
              <SvgNotificationBell />
              {!!unreadMessages && (
                <div style={styles.unreadMessage}>
                  <TextFont size={9} color="white">
                    {unreadMessages}
                  </TextFont>
                </div>
              )}
            </button>

            <div style={styles.profileTitle}>
              <RenderWithCondition condition={permissons.VIEW_FULL_PROFILE}>
                <ProfileUserAvatar avatarFileId={user?.avatarFileId} />
              </RenderWithCondition>
              <RenderWithCondition condition={!!(user?.firstName || user?.lastName)}>
                <TextFont style={{ ...styles.text, fontSize: 24, marginBottom: 4 }}>{`${user?.firstName ?? ""} ${
                  user?.lastName ?? ""
                }`}</TextFont>
              </RenderWithCondition>
              <InputMask
                mask="+7 999 999 99 99"
                style={{ ...styles.text, color: "#C7C7C7", textAlign: "center", marginBottom: 5 }}
                value={user?.login || user?.workPhone || user?.workPhoneInner || ""}
              />
            </div>
          </div>
          <div style={{ padding: "0 12px", overflowY: "auto", height: "100%" }}>
            {(isAssistant ? tabsAssistant : tabs).map((tab) =>
              !user?.active && !isAssistant && tab.title === "groupsParticipants" ? null : (
                <button key={tab.title} style={styles.button} onClick={() => setActiveTab(tab.title)}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {tab.icon}
                    <TextFont type="bold" style={styles.textButton}>
                      <FormattedMessage id={tab.title} />
                    </TextFont>
                  </div>

                  <div style={{ display: "flex", gap: 8 }}>
                    {tab.title === "notifications" && !!unreadMessages && (
                      <div style={styles.notifyNumber}>
                        <TextFont size={14} color={"white"}>
                          {unreadMessages}
                        </TextFont>
                      </div>
                    )}
                    <ArrowSvg type="right" fill="#B3BEB8" width={26} height={26} />
                  </div>
                </button>
              ),
            )}

            <RenderWithCondition condition={isDogmaUser && !isAssistant}>
              <button style={styles.button} onClick={getAdminUrl}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SvgAdminPanel />
                  <TextFont type="bold" style={styles.textButton}>
                    <FormattedMessage id="openDashboard" />
                  </TextFont>
                </div>
                <ArrowSvg type="right" fill="#B3BEB8" width={26} height={26} />
              </button>
            </RenderWithCondition>

            <RenderWithCondition condition={permissons.DELETE_OWN_USER && !isAssistant}>
              <button onClick={() => setisVisibleDelete(true)} style={{ ...styles.exit, marginTop: 22, paddingLeft: 10 }}>
                <SvgDelete fill="#B3BEB8" />
                <TextFont style={{ color: "#B3BEB8", marginLeft: 8, fontSize: 18 }}>
                  <FormattedMessage id="deleteProfile" />
                </TextFont>
              </button>
            </RenderWithCondition>
            <button style={styles.exit} onClick={() => setisVisibleExit(true)}>
              <SvgExit />
              <TextFont style={{ color: Colors.LIGHT.red, fontSize: 18, fontWeight: "400", marginLeft: 10 }}>
                <FormattedMessage id={isAssistant ? "exitFromApp" : "exit"} />
              </TextFont>
            </button>
          </div>

          <ModalRenderWithCondition condition={isVisibleExit}>
            <NotificationConfirm
              phraseId={msgExitExeption}
              phraseOkId={"exit"}
              onOk={handleLogOut}
              onCancel={() => setisVisibleExit(false)}
            />
          </ModalRenderWithCondition>
          <ModalRenderWithCondition condition={isVisibleDelete}>
            <NotificationConfirm
              phraseId={msgDeleteExeption}
              phraseOkId={"delete"}
              onOk={handleDeleteUser}
              onCancel={() => setisVisibleDelete(false)}
              styleTextOk={{ color: Colors.LIGHT.red }}
            />
          </ModalRenderWithCondition>
          <ModalRenderWithCondition condition={isVisibleEdit && !!dataFio}>
            <ModalEditFIO isVisible={isVisibleEdit} startData={dataFio} close={() => setisVisibleEdit(false)} />
          </ModalRenderWithCondition>
        </div>
      </LoaderRenderWithCondition>
    </div>
  );
};

const styles = {
  container: {
    display: "grid",
    gridTemplateRows: "1fr 2fr",
    backgroundColor: "#F5F9F8",
    height: "100%",
  },
  profile: {
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    padding: "5% 11px 20px 11px",
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    marginBottom: 20,
  },
  profileImage: {
    width: 95,
    height: 95,
    marginBottom: 10,
    borderRadius: 95,
    border: "3px solid #fff",
  },
  profileTitle: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
  },
  text: {
    color: "#fff",
    fontWeight: "400",
    fontSize: 16,
  },
  absolute: {
    top: 40,
    zIndex: 999,
  },
  button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 12px",
    backgroundColor: "#FFFFFF",
    borderRadius: 11,
    width: "100%",
    marginBottom: 10,
  },
  textButton: {
    marginLeft: 8,
    fontSize: 16,
    fontWeight: "700",
  },
  exit: {
    display: "flex",
    alignItems: "center",
    marginTop: 50,
    paddingLeft: 10,
  },
  notifyNumber: {
    borderRadius: 55,
    backgroundColor: Colors.LIGHT.red,
    padding: "4px 5px",
  },
  unreadMessage: {
    position: "absolute",
    top: -2,
    right: -1,
    borderRadius: 55,
    backgroundColor: Colors.LIGHT.red,
    paddingLeft: 1,
    paddingRight: 1,
  },
};
